import { useStaticQuery, graphql } from 'gatsby';

export default function useAllAdsData() {
  const response = useStaticQuery(
    graphql`
      query {
        allContentfulAd {
          nodes {
            adPDF {
              file {
                url
              }
              contentful_id
            }
            dateOfAd
          }
        }
      }
    `
  );

  return normalizeAllAdsData(response);
}

function normalizeAllAdsData({ allContentfulAd: { nodes } }) {
  const normalizedAllAdsData = nodes.map(node => {
    return {
      adURL: node.adPDF.file.url,
      id: node.adPDF.contentful_id,
      date: new Date(node.dateOfAd),
    };
  });

  return sortAdsDescending(normalizedAllAdsData);
}

function sortAdsDescending(ads) {
  return ads.sort((a, b) => b.date - a.date);
}
