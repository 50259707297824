import React from 'react';

import Layout from '../global/Layout';
import Hero from '../global/Hero';
import Ads from './Ads';

const Entry = () => {
  return (
    <Layout>
      <Hero
        title="Want crazy deals?"
        subtitle="View and download any of our ads."
      />
      <Ads />
    </Layout>
  );
};

export default Entry;
