import React from 'react';

import SectionContainer from '../global/SectionContainer';
import Ad from './Ad';

import useAllAdsData from '../../hooks/useAllAdsData';

import * as styles from './Ads.style';

const Ads = () => {
  const [firstAd, ...rest] = useAllAdsData();

  return (
    <>
      <SectionContainer>
        <div css={styles.wrapper}>
          <h4 className="subtitle is-4 has-text-centered-tablet">Latest Ad</h4>
          <Ad {...firstAd} />
        </div>
      </SectionContainer>
      <SectionContainer>
        <div css={styles.wrapper}>
          <h4 className="subtitle is-4 has-text-centered-tablet">Older Ads</h4>
          {rest.map(ad => (
            <Ad key={ad.id} {...ad} />
          ))}
        </div>
      </SectionContainer>
    </>
  );
};

export default Ads;
