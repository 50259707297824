import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  adURL: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
};

const Ad = ({ adURL, date }) => {
  return (
    <div className="notification">
      <div className="level">
        <div className="level-item has-text-centered">
          <div>
            <p className="heading">Ad version</p>
            <p className="title is-5">{date.toDateString()}</p>
          </div>
        </div>
        <div className="level-item">
          <a className="button is-primary is-outlined" href={adURL}>
            View &amp; Download
          </a>
        </div>
      </div>
    </div>
  );
};

export default Ad;
